import * as R from "remeda";
import { getApiAudioRecordGet, TextDocument, usePostApiAudioRecordTextDocumentCreate, usePostApiTextSummarization, usePutApiAudioRecordTextDocumentUpdate } from "@/API"
import { Box, Flex } from "@mantine/core"
import { PropsWithChildren, useRef } from "react"
import * as Y from 'yjs'
import { byteArrayToString } from "./SignalrProvider/encodingUtils";
import { useEditor } from "@tiptap/react";
import { RichTextEditor } from "@mantine/tiptap";
import StarterKit from '@tiptap/starter-kit';
import Collaboration from "@tiptap/extension-collaboration";
import { Markdown } from "tiptap-markdown";

export function CreateSummaryButton(props: { workspaceId: string, audioRecordId: string, onStart?: () => void, onEnd?: (success: boolean, msg: string, doc: TextDocument) => void } & PropsWithChildren) {
    const create_Mutation = usePostApiAudioRecordTextDocumentCreate()
    const update_Mutation = usePutApiAudioRecordTextDocumentUpdate()
    const summarization_Mutation = usePostApiTextSummarization({ axios: { headers: { "Content-Type": "application/json" } } })
    const yDoc = useRef<Y.Doc>(new Y.Doc())
    const editor = useEditor({
        extensions: [
            StarterKit,
            Markdown,
            Collaboration.configure({
                document: yDoc.current,
            }),
        ],
    });
    return <>
        <RichTextEditor hidden={true} editor={editor}>
            <>
            </>
        </RichTextEditor>
        <Box onClick={async () => {
            props.onStart?.()
            var record = await getApiAudioRecordGet({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
            var textList = []
            var text = ""
            R.forEach(record.data?.recordEntries ?? [], (entry, indx) => {
                if (entry.text) {
                    if (text.length + entry.text.length > 2000) {
                        textList.push(text)
                        text = ""
                    }
                    text += entry.text + "\n"
                }
            })
            if (text) textList.push(text)
            var summary = ""
            for (var i = 0; i < textList.length; i++) {
                var summarization_result = await summarization_Mutation.mutateAsync({ data: textList[i] })
                summary += summarization_result.data + "\n"
            }
            var createResult = await create_Mutation.mutateAsync({ params: { name: "總結", workspaceId: props.workspaceId, audioRecordId: props.audioRecordId } })
            var doc = R.clone(createResult.data)
            doc.textType = "Markdown"
            doc.text = summary;
            editor?.commands.setContent(summary)
            doc.rawTextType = "Yjs"
            doc.rawText = byteArrayToString(Y.encodeStateAsUpdate(yDoc.current))

            var update = await update_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId }, data: doc })
            props.onEnd?.(true, "", doc)
        }} >{props.children}</Box >

    </>
}