import { LinePayAdminIndex } from '@/Admin/LinePay'
import { createFileRoute } from '@tanstack/react-router'

type LinePaySearch = {
  orderId?: string,
  userId?: string
}
export const Route = createFileRoute('/admin/LinePay')({
  component: () => <LinePayAdminIndex />,
  validateSearch: (search: Record<string, unknown>): LinePaySearch => {
    return {
      orderId: search.orderId as string,
      userId: search.userId as string
    }
  },
})