import * as R from "remeda"
import { deleteApiAuthLogout, useGetApiAppKeyList } from "@/API"
import { useAuthStore } from "@/Stores/AuthStore"
import { Box, Button, ButtonProps, Card, Center, Group, List, Loader, LoadingOverlay, Stack, Text } from "@mantine/core"
import { useEffect } from "react"
import { useOs } from "@mantine/hooks"
import { useClipboard } from '@mantine/hooks';
import { notifications } from "@mantine/notifications"
import { LoginButton, LoginPage, LogoutButton } from "@/Auth/LoginPage"
import { modals } from "@mantine/modals"
import { IconLogout } from "@tabler/icons-react"

export function SelectAppKeyPage() {
    const os = useOs();
    var auth = useAuthStore()
    var list = useGetApiAppKeyList()
    const clipboard = useClipboard({ timeout: 500 });

    return <Box pos="relative">
        <LoadingOverlay
            visible={auth.isUpdating}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        {auth.data.role == "admin" ? <Box m={10}>
            <Text>管理員無法使用授權金鑰，請切換成使用者後再試一次</Text>
            <LogoutButton variant="outline" w={200} navigateoptions={{ to: "/nav/SelectAppKey" }} />
        </Box> : <>
            {auth.data && auth.data.userName ? <>
                <Group m={10}>
                    <Text>請選擇授權金鑰</Text>
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "flex-end" }}>
                        <LogoutButton variant="outline" w={200} navigateoptions={{ to: "/nav/SelectAppKey" }} />
                    </div>
                </Group>
                <Stack>
                    {list.isSuccess ? list.data.data.map((x, idx) =>
                        <Button key={`appkey_${idx}`} w="100%" h={100} variant="light" onClick={() => {
                            clipboard.copy(x.keyId!)
                            notifications.show({ message: "已複製授權金鑰至剪貼簿，請至APP輸入金鑰" })
                            if (os == "windows" || os == "android") {
                                window.location.assign(`cakescribe://?appkey=${x.keyId!}`)
                            }
                        }}>
                            <Text fw={700} truncate="end" style={{ fontSize: "40px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
                                {!R.isEmpty(x.comment ?? "") ? x.comment : x.keyId}
                            </Text>
                        </Button>
                    ) : <Center><Loader size={80} m={50} /></Center>}
                </Stack> </> : <>
                <Center>
                    <Text fw={700} style={{ fontSize: "60px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
                        快可錄
                    </Text>
                </Center>
                <Center>
                    <Card shadow="sm" padding="lg" radius="md" withBorder w={500}>
                        <LoginPage redirect="/nav/SelectAppKey" onLogin={() => { }} />
                    </Card>
                </Center>
            </>}
        </>
        }
    </Box>
}