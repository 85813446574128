import { useDeleteApiAudioRecordTextDocumentImageDelete, useGetApiAudioRecordTextDocumentImageList } from "@/API";
import { SimpleGrid, Image, Box, Pagination, ActionIcon, Text, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { GetTextDocumentImageUrl } from "@/APIURL";

export function TextDocumentImageBox(props: { workspaceId: string, audioRecordId: string, onClickFile?: (fileName: string) => void }) {
    const queryClient = useQueryClient()
    const images = useGetApiAudioRecordTextDocumentImageList({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
    const delete_Mutation = useDeleteApiAudioRecordTextDocumentImageDelete()
    const pageSize = 12
    const [activePage, setPage] = useState(1);
    return <>
        <Pagination value={activePage} onChange={setPage} total={Math.ceil((images.data?.data.length ?? 0) / pageSize)} />
        <SimpleGrid mt={10} cols={4}>
            {images.data?.data.slice((activePage - 1) * pageSize, activePage * pageSize,).map(d => {
                var url = GetTextDocumentImageUrl(props.workspaceId, props.audioRecordId, d)
                return <Box pos="relative" w="100px" h="100px">
                    <ActionIcon variant="light" color="red" pos="absolute" top={0} right={0} onClick={() => {
                        modals.openConfirmModal({
                            title: '刪除圖片',
                            centered: true,
                            children: (
                                <>
                                    <Text size="sm">
                                        是否要刪除圖片?
                                    </Text>
                                    <Image
                                        w="400px"
                                        h="400px"
                                        fit="contain"
                                        radius="md"
                                        src={url}
                                    />
                                </>
                            ),
                            labels: { confirm: '確定刪除', cancel: "取消" },
                            confirmProps: { color: 'red' },
                            onConfirm: async () => {
                                try {
                                    await delete_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId, fileName: d } })
                                    queryClient.invalidateQueries({ queryKey: images.queryKey })
                                    notifications.show({ message: "刪除成功" })
                                } catch {
                                    notifications.show({ message: "刪除失敗", color: "red" })
                                }
                            },
                        });

                    }} >
                        <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                    <Image
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            props.onClickFile?.(d)
                        }}
                        w="100px"
                        h="100px"
                        fit="contain"
                        radius="md"
                        src={url}
                    />
                </Box>
            })}
        </SimpleGrid>
    </>
}