import { Text, Box, Breadcrumbs, Flex } from "@mantine/core";
import { AudioRecordItem } from "./AudioRecordItem/AudioRecordItem";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { TextDocumentList } from "./TextDocument/TextDocumentList";
import { Link, useParams } from "@tanstack/react-router";
import { useGetApiAudioRecordGet, useGetApiWorkspaceGet } from "@/API";

export function AudioRecordIndex() {
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    return <Flex h="100%" direction="column">
        <Breadcrumbs mb={5} className="no-underline">
            <Link to="/workspace">
                <Text c="gray">
                    工作空間列表
                </Text>
            </Link>
            <Link to="/workspace/$workspaceId" params={{ workspaceId: workspaceId }} >
                <Text c="gray">
                    {workspace.data?.data.name}
                </Text>
            </Link>
            <Text fw={500} c="var(--mantine-primary-color-filled)">
                {audioRecord.data?.data.name}
            </Text>
        </Breadcrumbs>
        <Allotment>
            <Box w="100%" h="100%" pr={10}>
                <AudioRecordItem />
            </Box>
            <TextDocumentList />
        </Allotment>
    </Flex>
}