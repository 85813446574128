import dayjs from "dayjs";
import { AddCreditKeyViewModel, CreditKey, getGetApiAdminCreditKeyListQueryKey, usePostApiAdminCreditKeyAdd } from "@/API";
import { DateInput, DatePickerInput, DateTimePicker } from '@mantine/dates';
import { Button, Group, Modal, NumberInput, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import 'dayjs/locale/zh-tw';

export function AddCreditKeyDialog(props: { userId: string, opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const CreateKey_Mutation = usePostApiAdminCreditKeyAdd()
    const [expiryTime, setExpiryTime] = useState<Date | null>(dayjs(new Date()).add(1, "month").toDate());
    const form = useForm<AddCreditKeyViewModel>({
        initialValues: {
            balance: 0,
            comment: ""
        },
    });
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="新增儲值金鑰">
            <form onSubmit={form.onSubmit(async (values) => {
                try {
                    await CreateKey_Mutation.mutateAsync({
                        params: { userId: props.userId }, data: {
                            comment: values.comment,
                            balance: (values.balance ?? 0) * 60 * 60,
                            expiryTime: expiryTime?.toISOString()
                        }
                    })
                    queryClient.invalidateQueries({ queryKey: getGetApiAdminCreditKeyListQueryKey() })
                    notifications.show({ message: "新增成功" })
                    props.onClose()
                } catch (e) {
                    notifications.show({ message: "新增失敗", color: "red" })
                }
            })}>
                <NumberInput label="儲值時間(小時)" {...form.getInputProps('balance')} />
                <DateTimePicker
                    label="過時時間"
                    locale="zh-tw"
                    value={expiryTime}
                    onChange={setExpiryTime}
                />
                <Textarea
                    label="說明"
                    rows={5}
                    {...form.getInputProps('comment')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit">新增</Button>
                </Group>
            </form>
        </Modal>
    </>
}