import { useGetApiWorkspaceUserCheckPendingUser, usePostApiWorkspaceUserRequestPendingUser } from "@/API"
import { Box, Button, Flex, LoadingOverlay } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

export function RequestWorkspaceAuthPage(props: { workspaceId: string }) {
    const queryClient = useQueryClient()
    const requestAuth_Mutation = usePostApiWorkspaceUserRequestPendingUser()
    const check = useGetApiWorkspaceUserCheckPendingUser({ workspaceId: props.workspaceId })
    const [requestLoading, setRequestLoading] = useState(false)
    return <Box pos="relative" h="100%">
        <LoadingOverlay
            visible={check.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Flex justify="center" align="center" h="100%">
            {check.data?.data ? <Button disabled={true} size="xl">已發出請求</Button> : <Button size="xl" variant="gradient" loading={requestLoading} onClick={async () => {
                try {
                    await requestAuth_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId } })
                    queryClient.invalidateQueries({ queryKey: check.queryKey })
                } catch (e) {
                    notifications.show({ message: "發生錯誤", color: "red" })
                }
            }}>請求權限</Button>}
        </Flex>
    </Box>
}