import { Center,Text } from "@mantine/core";

export function Index() {
  return (
    <>
      <Center>
        <Text fw={700} style={{ fontSize: "60px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
          快可錄
        </Text>
      </Center>
      <Center>
        <Text fw={700} style={{ fontSize: "25px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
          Tempest Digital Co., Ltd.
        </Text>
      </Center>
    </>
  )
}