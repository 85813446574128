import * as R from "remeda"
import { useDeleteApiWorkspaceUserDeleteUser, useGetApiWorkspaceUserListUsers, usePutApiWorkspaceUserSetUserRole } from "@/API"
import { ActionIcon, Text, Box, Group, Pagination, Flex } from "@mantine/core"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useMemo, useState } from "react"
import { DataTable } from "mantine-datatable"
import { modals } from "@mantine/modals"
import { IconEdit, IconTrash, IconUserCircle } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { notifications } from "@mantine/notifications"
import { useAuthStore } from "@/Stores/AuthStore"
import { WorkspaceUserRoleBadge } from "../WorkspaceUserRoleText"
import { SelectWorkspaceUserRoleButton } from "./WorkspaceUserRole"

export function WorkspaceUsersSettings(props: {}) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const auth = useAuthStore()
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/settings/users" })
    const users = useGetApiWorkspaceUserListUsers({ workspaceId: workspaceId })
    const SetUserRole_Mutation = usePutApiWorkspaceUserSetUserRole()
    const DeleteUser_Mutation = useDeleteApiWorkspaceUserDeleteUser()
    const [activePage, setPage] = useState(1);
    const pageCount = 10
    const user = useMemo(() => {
        return users.data?.data.find(x => x.userName == auth.data.userName)
    }, [users])

    return <Box>
        {users.isSuccess && <>
            <Pagination value={activePage} onChange={setPage} total={R.ceil(((users.data?.data.length ?? 0) / pageCount), 0)} />
            <DataTable
                minHeight={users.data.data.length > 0 ? 0 : 150}
                noRecordsText="沒有使用者"
                withTableBorder
                columns={[
                    {
                        accessor: 'userName', title: "使用者名稱", render: (data) =>
                            <Flex direction="row" align="center">
                                <Text mr={5}>{data.userName}</Text><WorkspaceUserRoleBadge role={data.role!} />
                            </Flex>
                    },
                    {
                        accessor: 'actions',
                        title: <Box mr={6}>操作</Box>,
                        textAlign: 'right',
                        render: (data) => (
                            <Group key={`op_${data.userName}`} gap={4} justify="right" wrap="nowrap">
                                {(user?.userName != data.userName && (user?.role == "Owner" || data.role != "Owner")) &&
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                    >
                                        <SelectWorkspaceUserRoleButton title="選擇使用者角色" initValue={data.role!} options={["Viewer", "Editor", "Manager"]} onConfirm={async v => {
                                            if (data.userName) {
                                                try {
                                                    await SetUserRole_Mutation.mutateAsync({
                                                        params: {
                                                            workspaceId: workspaceId, userName: data.userName, role: v
                                                        }
                                                    })
                                                    queryClient.invalidateQueries({ queryKey: users.queryKey })
                                                    notifications.show({ message: "設定成功" })
                                                } catch (e) {
                                                    notifications.show({ message: "設定失敗", color: "red" })
                                                }
                                            }
                                        }}>
                                            <IconUserCircle size={16} />
                                        </SelectWorkspaceUserRoleButton >
                                    </ActionIcon>
                                }
                                {auth.data.userName != data.userName &&
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="red"
                                        onClick={() => {
                                            modals.openContextModal({
                                                modal: "ConfirmCountDown",
                                                title: '刪除使用者',
                                                innerProps: {
                                                    countDownSecond: 3,
                                                    children: (
                                                        <Text size="sm">
                                                            是否要刪除使用者"{data.userName}"?
                                                        </Text>
                                                    ),
                                                    labels: {
                                                        confirm: '刪除', cancel: '取消'
                                                    },
                                                    confirmProps: { color: 'red' },
                                                    onConfirm: async () => {
                                                        try {
                                                            if (data.userName) {
                                                                await DeleteUser_Mutation.mutateAsync({ params: { workspaceId: workspaceId, userName: data.userName } })
                                                                queryClient.invalidateQueries({ queryKey: users.queryKey })
                                                                notifications.show({ message: "刪除成功" })
                                                            }
                                                        } catch (e) {
                                                            notifications.show({ message: "刪除失敗", color: "red" })
                                                        }
                                                    },
                                                },

                                            });
                                        }}
                                    >
                                        <IconTrash size={16} />
                                    </ActionIcon>}
                            </Group>
                        ),
                    },
                ]}
                records={users.data.data.map(x => { return { ...x, id: x.userName } })}
            />
        </>}
    </Box>
}