import { AudioRecordEntry, DataUpdateMeta, useDeleteApiAudioRecordClearAllEntries, useDeleteApiAudioRecordDeleteAudio, useGetApiAudioRecordGet, useGetApiAudioRecordGetReference, useGetApiAudioRecordJobGetTranscriptionJob, usePutApiAudioRecordUpdateEntry } from "@/API"
import * as signalR from "@microsoft/signalr";
import { useParams } from "@tanstack/react-router"
import { RecordEntryCard } from "./RecordEntryCard"
import { useMemo, useRef, useState } from "react";
import { ActionIcon, Box, Button, Text, Center, Flex, LoadingOverlay, Menu, Modal, rem, Loader, Tooltip } from "@mantine/core";
import { VirtualList } from "@/Components/VirtualList";
import { AudioPlayer, AudioPlayerHandle } from "@/Components/AudioPlayer";
import { useDisclosure } from "@mantine/hooks";
import { EditRecordEntryForm } from "./EditRecordEntryForm";
import { EditRecordEntrySpeakerForm } from "./EditRecordEntrySpeakerForm";
import { useQueryClient } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { UseSignalrState } from "@/Helper/UseSignalrState";
import { SignalrStateBadge } from "@/Components/SignalrStateBadge";
import { UploadAudioRecordFileButton } from "./UploadAudioRecordFileButton";
import { CreateAudioTranscriptionJobButton } from "./AudioTranscriptionJob/CreateAudioTranscriptionJobButton";
import { IconDots, IconDownload, IconTransform, IconTrash } from "@tabler/icons-react";
import { AudioTranscriptionJobInfo } from "./AudioTranscriptionJob/AudioTranscriptionJobInfo";
import { modals } from "@mantine/modals";
import { UseWorkspaceAuth } from "@/Workspaces/UseWorkspaceAuth";
import { TextSaveToFile, UrlSaveToFile } from "@/Helper/SaveToFile";
import { AudioRecordToSrt } from "@/Helper/AudioRecordHelper";
import { GetAudioRecordAudioUrl } from "@/APIURL";
import { EditRecordAllSpeakersForm } from "./EditRecordAllSpeakersForm";

export function AudioRecordItem() {
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId" })
    const queryClient = useQueryClient()
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const audioRecordRef = useGetApiAudioRecordGetReference({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const clearAudioRecord_Mutation = useDeleteApiAudioRecordClearAllEntries()
    const [clearAudioRecordLoading, setClearAudioRecordLoading] = useState(false)
    const deleteAudio_Mutation = useDeleteApiAudioRecordDeleteAudio()
    const [deleteAudioLoading, setDeleteAudioLoading] = useState(false)
    const transcriptionJob = useGetApiAudioRecordJobGetTranscriptionJob({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const [transcriptionJobInfo_opened, { open: transcriptionJobInfoopen, close: transcriptionJobInfoclose }] = useDisclosure(false);
    const recordEntries = audioRecord.data?.data.recordEntries ?? []
    const [currentPlayingTime, setCurrentPlayingTime] = useState(0)
    const audioPlayer = useRef<AudioPlayerHandle>(null)
    const [updateEntry_opened, { open: updateEntry_open, close: updateEntry_close }] = useDisclosure(false);
    const [updateEntrySpeakers_opened, { open: updateEntrySpeakers_open, close: updateEntrySpeakers_close }] = useDisclosure(false);
    const [selectedRecordEntry, setSelectedRecordEntry] = useState<AudioRecordEntry>()
    const updateEntry_Mutation = usePutApiAudioRecordUpdateEntry()
    const [hubConnection, setHubConnection] = useState<signalR.HubConnection | null>(null);
    const { SetConnection, connectionState } = UseSignalrState()
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/audioRecordHub")
            .withAutomaticReconnect()
            .withStatefulReconnect()
            .build();
        SetConnection(connection)
        connection.on("OnUpdateEntry", (meta: DataUpdateMeta, entry: AudioRecordEntry) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnDeleteEntry", (meta: DataUpdateMeta, entryId: string) => {
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection.on("OnUpdate", (meta: DataUpdateMeta) => {
            if (transcriptionJobExist) queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
            queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
        })
        connection
            .start()
            .then(async () => {
                console.log('SignalR Connected!');
                var success = await connection.invoke<boolean>("ListenAudioRecordEvent", workspaceId, audioRecordId)
                if (success)
                    console.log("ListenAudioRecordEvent success")
                else
                    console.warn("ListenAudioRecordEvent fail")
            })
            .catch((err) => {
                console.log('SignalR Connection Error: ', err);
            });
        setHubConnection(connection)
        return () => {
            if (connection) {
                connection
                    .stop()
                    .then(() => {
                        console.log('SignalR Disconnected!');
                    })
                    .catch((err) => {
                        console.log('SignalR Disconnection Error: ', err);
                    });
            }
        };
    }, [])
    const transcriptionJobExist = useMemo(() => {
        return !!transcriptionJob.data?.data
    }, [transcriptionJob.data, transcriptionJob.status])
    useEffect(() => {
        if (!transcriptionJobExist) transcriptionJobInfoclose();
    }, [transcriptionJobExist])
    const recordExist = useMemo(() => {
        if (recordEntries.length > 0 && transcriptionJob.data?.data) {
            queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
        }
        return recordEntries.length > 0
    }, [audioRecord.data])
    const audioExist = useMemo(() => {
        return audioRecordRef.data?.data.audioIds && audioRecordRef.data.data.audioIds.length > 0;
    }, [audioRecordRef.data])

    return <Box h="100%" pos="relative">
        <LoadingOverlay
            visible={audioRecord.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <SignalrStateBadge style={{ zIndex: 1 }} state={connectionState} pos="absolute" right={20} top={0} />
        <Flex align="center">
            {workspaceEditorAuth &&
                <Menu withinPortal position="bottom-start" shadow="sm">
                    <Menu.Target>
                        <ActionIcon variant="subtle" color="gray">
                            <IconDots style={{ width: rem(16), height: rem(16) }} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {transcriptionJobExist &&
                            <Menu.Item
                                leftSection={<IconTransform style={{ width: rem(14), height: rem(14) }} />}
                                color="var(--mantine-primary-color-filled)"
                                onClick={transcriptionJobInfoopen}
                            >
                                轉錄資訊
                            </Menu.Item>
                        }
                        {!transcriptionJobExist && recordExist &&
                            <Menu.Item
                                leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                color="red"
                                onClick={() => {
                                    modals.openConfirmModal({
                                        title: '清空所有轉錄紀錄',
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                是否要清空所有轉錄紀錄
                                            </Text>
                                        ),
                                        labels: { confirm: '清空', cancel: "取消" },
                                        confirmProps: { color: 'red', loading: clearAudioRecordLoading },
                                        onConfirm: async () => {
                                            setClearAudioRecordLoading(true)
                                            try {
                                                await clearAudioRecord_Mutation.mutateAsync({
                                                    params: {
                                                        workspaceId: workspaceId, audioRecordId: audioRecordId
                                                    }
                                                })
                                                queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                                                notifications.show({ message: "清空成功" })
                                            } catch (e) {
                                                notifications.show({ message: "清空失敗", color: "red" })
                                            }
                                            setClearAudioRecordLoading(false)
                                        },
                                    });
                                }}
                            >
                                清空所有轉錄紀錄
                            </Menu.Item>
                        }
                        {!transcriptionJobExist && audioExist &&
                            <Menu.Item
                                leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                color="red"
                                onClick={() => {
                                    modals.openConfirmModal({
                                        title: '刪除音檔',
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                是否要刪除音檔
                                            </Text>
                                        ),
                                        labels: { confirm: '刪除', cancel: "取消" },
                                        confirmProps: { color: 'red', loading: deleteAudioLoading },
                                        onConfirm: async () => {
                                            setDeleteAudioLoading(true)
                                            try {
                                                audioPlayer.current?.SetPlaying(false)
                                                await deleteAudio_Mutation.mutateAsync({
                                                    params: {
                                                        workspaceId: workspaceId, audioRecordId: audioRecordId
                                                    }
                                                })
                                                queryClient.invalidateQueries({ queryKey: audioRecordRef.queryKey })
                                                notifications.show({ message: "刪除成功" })
                                            } catch (e) {
                                                notifications.show({ message: "刪除失敗", color: "red" })
                                            }
                                            setDeleteAudioLoading(false)
                                        },
                                    });
                                }}
                            >
                                刪除音檔
                            </Menu.Item>
                        }
                    </Menu.Dropdown>
                </Menu>
            }
            {transcriptionJobExist && <Tooltip label="轉錄中..." color="gray">
                <Loader type="dots" size="sm" />
            </Tooltip>}
            {audioExist &&
                <Button radius="xl" size="compact-xs" onClick={() => {
                    UrlSaveToFile(GetAudioRecordAudioUrl(workspaceId, audioRecordId), audioRecordRef.data?.data.audioIds?.at(0) ?? "Audio.wav")
                }}>音檔<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>}
            {recordExist &&
                <Button radius="xl" size="compact-xs" onClick={() => {
                    var srt = AudioRecordToSrt(audioRecord.data?.data!)
                    if (srt) TextSaveToFile(`${audioRecord.data?.data.name}.txt`, srt)
                }}>文字<IconDownload size={14} style={{ marginLeft: "3px" }} /></Button>}
            {audioRecord.data?.data && <EditRecordAllSpeakersForm audioRecord={audioRecord.data.data} />}
        </Flex>
        <Box h="calc(100% - 170px)">
            {audioExist && !recordExist && !transcriptionJobExist &&
                <Flex h="100%" align="center" justify="center">
                    <CreateAudioTranscriptionJobButton workspaceId={workspaceId} audioRecordId={audioRecordId} />
                </Flex>}
            {clearAudioRecordLoading ? <Center><Loader size="xl" /></Center> :
                <VirtualList data={recordEntries} components={(data, index) => <RecordEntryCard key={`RecordEntryCard_${index}`} isEditable={workspaceEditorAuth} recordEntry={data[index]}
                    isSelected={currentPlayingTime >= (data[index].time ?? 0) && currentPlayingTime < (data[index].time ?? 0) + (data[index].duration ?? 0)}
                    onPlay={() => {
                        if (data[index].time != undefined) {
                            audioPlayer.current?.Seek(data[index].time)
                            audioPlayer.current?.SetPlaying(true)
                        }

                    }}
                    onEdit={() => {
                        setSelectedRecordEntry(data[index])
                        updateEntry_open();
                    }}
                    onEditSpeakers={() => {
                        setSelectedRecordEntry(data[index])
                        updateEntrySpeakers_open();
                    }}
                />} heightOffset={0} />
            }
        </Box>

        {!audioExist && <UploadAudioRecordFileButton workspaceId={workspaceId} audioRecordId={audioRecordId} />}
        {deleteAudioLoading ? <Center><Loader size="xl" /></Center> :
            <AudioPlayer key={`AudioPlayer_${audioRecordRef.data?.data.audioIds?.at(0)}`} ref={audioPlayer} url=
                {GetAudioRecordAudioUrl(workspaceId, audioRecordId, audioRecordRef.data?.data.audioIds?.at(0))}
                onSecondProgress={v => setCurrentPlayingTime(v)}
            />
        }
        <Modal opened={updateEntry_opened} onClose={updateEntry_close} title="編輯">
            <EditRecordEntryForm initValue={selectedRecordEntry ?? {}} onSubmit={async entry => {
                updateEntry_close();
                try {
                    await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: entry })
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    notifications.show({ message: "更新成功" })
                } catch (e) {
                    notifications.show({ message: "更新失敗", color: "red" })
                }
                console.log(entry)
            }} />
        </Modal>
        <Modal opened={updateEntrySpeakers_opened} onClose={updateEntrySpeakers_close} title="編輯語者">
            <EditRecordEntrySpeakerForm initValue={selectedRecordEntry ?? {}} onSubmit={async entry => {
                updateEntrySpeakers_close();
                try {
                    await updateEntry_Mutation.mutateAsync({ params: { workspaceId: workspaceId, audioRecordId: audioRecordId }, data: entry })
                    queryClient.invalidateQueries({ queryKey: audioRecord.queryKey })
                    notifications.show({ message: "更新成功" })
                } catch (e) {
                    notifications.show({ message: "更新失敗", color: "red" })
                }
                console.log(entry)
            }} />
        </Modal>
        <Modal opened={transcriptionJobInfo_opened} onClose={transcriptionJobInfoclose} title="轉錄資訊">
            <AudioTranscriptionJobInfo workspaceId={workspaceId} audioRecordId={audioRecordId} onDelete={() => {
                transcriptionJobInfoclose()
                queryClient.invalidateQueries({ queryKey: transcriptionJob.queryKey })
            }} />
        </Modal>
    </Box>
}
