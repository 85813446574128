import { getApiWorkspaceUserGetRole, getGetApiAudioRecordListQueryKey, useGetApiAudioRecordList, useGetApiWorkspaceGet, useGetApiWorkspaceUserGetRole } from "@/API";
import { Box, SimpleGrid, Group, Text, Loader, LoadingOverlay, Pagination, Flex, Button, ActionIcon, Breadcrumbs, } from "@mantine/core";
import { Link, useLocation, useNavigate, useParams } from "@tanstack/react-router";
import { useState } from "react";
import { AudioRecordListCard } from "./AudioRecordListCard";
import { WorkspaceSearch } from "../Search";
import { CreateAudioRecordButton } from "./CreateAudioRecordButton";
import { IconSettings } from "@tabler/icons-react";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { RequestWorkspaceAuthPage } from "./RequestWorkspaceAuthPage";
import { ShareLink } from "@/Components/ShareLink";

export function WorkspaceItem() {
    const navigate = useNavigate()
    const location = useLocation()
    const [activePage, setPage] = useState(1);
    const pageSize = 20;
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/" })
    const workspaceAuth = UseWorkspaceAuth(workspaceId, "Manager")
    const viewerAuth = UseWorkspaceAuth(workspaceId, "Viewer")
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const audioRecordBaseList = useGetApiAudioRecordList({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }, {
        query: {
            queryKey: getGetApiAudioRecordListQueryKey({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }),
            enabled: viewerAuth
        }
    })


    return <Box pos="relative" h="100%">
        <LoadingOverlay
            visible={audioRecordBaseList.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Flex>
            <Breadcrumbs className="no-underline">
                <Link to="/workspace">
                    <Text c="gray">
                        工作空間列表
                    </Text>
                </Link>
                <Text fw={500} c="var(--mantine-primary-color-filled)">
                    {workspace.data?.data.name}
                </Text>
            </Breadcrumbs>
            {workspaceAuth &&
                <ActionIcon variant="subtle" size="md" ml="5px" onClick={() => navigate({
                    to: "/workspace/$workspaceId/settings/users", params: {
                        workspaceId: workspaceId
                    }
                })}>
                    <IconSettings style={{ width: '100%', height: '100%' }} stroke={1.5} />
                </ActionIcon>
            }
        </Flex>
        {viewerAuth ? <>
            <Group>
                <Pagination total={Math.ceil((audioRecordBaseList.data?.data.total ?? 0) / pageSize)} value={activePage} onChange={setPage} siblings={1} defaultValue={10} />
                <WorkspaceSearch workspaceIds={[workspaceId]} />
                <ShareLink url={window.origin + location.pathname} />
            </Group>

            <CreateAudioRecordButton workspaceId={workspaceId} >
                <Button>
                    新增文件
                </Button>
            </CreateAudioRecordButton>
            <Flex mt={10} gap={10} style={{ flexWrap: "wrap" }}>
                {audioRecordBaseList.data?.data.results?.map((d, idx) => {
                    return <AudioRecordListCard key={`AudioRecordListCard_${idx}`} audioRecord={d} onEnter={() => {
                        if (d.id) navigate({
                            to: "/workspace/$workspaceId/audioRecord/$audioRecordId", params: {
                                workspaceId: workspaceId,
                                audioRecordId: d.id
                            }
                        })
                    }} />

                })}
            </Flex ></> : <>
            <RequestWorkspaceAuthPage workspaceId={workspaceId} />
        </>}
    </Box>
}