import dayjs from "dayjs";
import { getGetApiAdminPeriodCreditKeyListQueryKey, usePostApiAdminPeriodCreditKeyAdd } from "@/API";
import { DateTimePicker } from '@mantine/dates';
import { Button, Group, Modal, NumberInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import 'dayjs/locale/zh-tw';

export function AddPeriodCreditKeyDialog(props: { userId: string, opened: boolean, onClose: () => void }) {
    const queryClient = useQueryClient()
    const CreateKey_Mutation = usePostApiAdminPeriodCreditKeyAdd()
    const [startTime, setStartTime] = useState<Date | null>(new Date());
    const form = useForm<{ balance: number, comment: string, duration: number }>({
        initialValues: {
            balance: 0,
            comment: "",
            duration: 1
        },
    });
    return <>
        <Modal opened={props.opened} onClose={props.onClose} title="新增週期儲值金鑰">
            <form onSubmit={form.onSubmit(async (values) => {
                try {

                    await CreateKey_Mutation.mutateAsync({
                        params: { userId: props.userId }, data: {
                            comment: values.comment,
                            balance: (values.balance ?? 0) * 60 * 60,
                            startTime: startTime?.toISOString(),
                            expiryTime: dayjs(startTime).add(values.duration, "month").toISOString(),
                        }
                    })
                    queryClient.invalidateQueries({ queryKey: getGetApiAdminPeriodCreditKeyListQueryKey() })
                    notifications.show({ message: "新增成功" })
                    props.onClose()
                } catch (e) {
                    notifications.show({ message: "新增失敗", color: "red" })
                }
            })}>
                <NumberInput label="儲值時間(小時)" {...form.getInputProps('balance')} />
                <DateTimePicker
                    label="開始時間"
                    locale="zh-tw"
                    value={startTime}
                    onChange={setStartTime}
                />
                <NumberInput label="週期(月)" {...form.getInputProps('duration')} />
                <Textarea
                    label="說明"
                    rows={5}
                    {...form.getInputProps('comment')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit">新增</Button>
                </Group>
            </form>
        </Modal>
    </>
}