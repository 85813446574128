import { Button, Modal, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PropsWithChildren } from "react";
import { EditAudioRecordNameForm } from "./EditAudioRecordNameForm";
import { useQueryClient } from "@tanstack/react-query";
import { getApiAudioRecordList, getGetApiAudioRecordListQueryKey, usePostApiAudioRecordCreate } from "@/API";
import { notifications } from "@mantine/notifications";

export function CreateAudioRecordButton(props: { workspaceId: string } & PropsWithChildren) {
    const queryClient = useQueryClient()
    const create_Mutation = usePostApiAudioRecordCreate()
    const [opened, { open, close }] = useDisclosure(false);
    return <>
        <span onClick={() => {
            open()
        }}>
            {props.children}
        </span>
        <Modal opened={opened} onClose={close} title="新增文件" centered>
            <EditAudioRecordNameForm initValue="" onSubmit={async (v) => {
                try {
                    await create_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId, name: v } })
                    queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                    notifications.show({ message: "新增成功" })
                    close();
                }
                catch (e) {
                    notifications.show({ message: "新增失敗", color: "red" })
                }
            }} />
        </Modal>
    </>
}