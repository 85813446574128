import { Button, ButtonProps } from "@mantine/core";
import { useMatchRoute, UseMatchRouteOptions, useNavigate } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

export function NavigationButton(props: PropsWithChildren<{ match: UseMatchRouteOptions } & ButtonProps>) {
    const navigate = useNavigate()
    const matchRoute = useMatchRoute()
    return <>
        <Button w="100%" h={45} variant={matchRoute(props.match) ? "light" : "subtle"} color={matchRoute(props.match) ? "" : "gray"} onClick={() => { navigate({ to: props.match.to }); }} style={{ display: "flex", flexDirection: "row" }} {...props}>
            {props.children}
        </Button>
    </>
}