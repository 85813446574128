import { AudioRecordBase, getApiAudioRecordGet, getGetApiAudioRecordListQueryKey, useDeleteApiAudioRecordDelete, usePutApiAudioRecordUpdate } from "@/API";
import { Text, Card, Group, Menu, ActionIcon, rem, Button, Modal, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import { EditAudioRecordNameForm } from "./EditAudioRecordNameForm";
import * as R from "remeda";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
export function AudioRecordListCard({ audioRecord, onEnter }: { audioRecord: AudioRecordBase, onEnter: () => void }) {
    const queryClient = useQueryClient()
    const workspaceEditorAuth = UseWorkspaceAuth(audioRecord.workspaceId!, "Editor")
    const deleteAudioRecord_Mutation = useDeleteApiAudioRecordDelete()
    const [deleteAudioRecordLoading, setDeleteAudioRecordLoading] = useState(false)
    const updateAudioRecord_Mutation = usePutApiAudioRecordUpdate()
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    return <Card w={400} shadow="sm" padding="lg" pt={5} radius="md" withBorder>
        <LoadingOverlay
            visible={deleteAudioRecordLoading}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Group justify="space-between" w="100%">
            <Group mt="md" mb="xs" w="100%">
                <Text fw={500} w="calc(100% - 30px)" truncate="end">{audioRecord.name}</Text>
            </Group>
            {workspaceEditorAuth &&
                <Menu withinPortal position="bottom-end" shadow="sm" >
                    <Menu.Target>
                        <ActionIcon variant="subtle" color="gray" pos="absolute" right={10} top={10}>
                            <IconDots style={{ width: rem(16), height: rem(16) }} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
                            onClick={() => {
                                editName_open()
                            }}
                        >
                            修改名稱
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                            color="red"
                            onClick={() => {
                                modals.openConfirmModal({
                                    title: '刪除錄音紀錄',
                                    children: (
                                        <Text size="sm">
                                            是否要刪除錄音紀錄 {audioRecord.name ?? ""}?
                                        </Text>
                                    ),
                                    labels: { confirm: '刪除', cancel: '取消' },
                                    confirmProps: { color: 'red', loading: deleteAudioRecordLoading },
                                    onConfirm: async () => {
                                        setDeleteAudioRecordLoading(true)
                                        try {
                                            if (audioRecord.id && audioRecord.workspaceId) {
                                                await deleteAudioRecord_Mutation.mutateAsync({ params: { workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id } })
                                                notifications.show({ message: "刪除成功" })
                                                queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                                            }
                                        } catch (ex) {
                                            notifications.show({ message: "刪除失敗", color: "red" })
                                        }
                                        setDeleteAudioRecordLoading(false)
                                    },
                                });
                            }}
                        >
                            刪除
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            }
        </Group>
        <Button color="blue" fullWidth mt="md" radius="md" onClick={() => {
            onEnter()
        }}>
            進入
        </Button>
        <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
            <EditAudioRecordNameForm initValue={audioRecord.name ?? ""} onSubmit={async v => {
                if (audioRecord.workspaceId && audioRecord.id) {
                    var record = await getApiAudioRecordGet({ workspaceId: audioRecord.workspaceId, audioRecordId: audioRecord.id })
                    var data = R.clone(record.data)
                    data.name = v
                    if (data.workspaceId) {
                        try {
                            await updateAudioRecord_Mutation.mutateAsync({ params: { workspaceId: data.workspaceId }, data: data })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordListQueryKey() })
                            editName_close()
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                }
            }} />
        </Modal>
    </Card>
}