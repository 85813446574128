import { AudioRecordEntry, PostApiWorkspaceCreateParams } from '@/API';
import { Button, Checkbox, Group, NumberInput, Stack, Textarea, TextInput, Text, TagsInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as R from "remeda";
export function EditRecordEntrySpeakerForm(props: { initValue: AudioRecordEntry, onSubmit: (data: AudioRecordEntry) => void }) {
    const form = useForm<{
        speakers: string[],
    }>({
        initialValues: {
            speakers: props.initValue.speakers ?? [],
        },
    });

    return (
        <form onSubmit={form.onSubmit((values) => {
            var data = R.clone(props.initValue)
            data.speakers = values.speakers
            props.onSubmit(data);
        })}>
            <div style={{ overflowY: 'hidden' }}>
                <TagsInput label="語者" {...form.getInputProps('speakers')} />
                <div style={{ marginTop: 20, textAlign: 'right' }}>
                    <Button type="submit" color="blue">
                        確認
                    </Button>
                </div>
            </div>
        </form>
    );
}