import * as R from "remeda"
import { useDeleteApiAdminUserManagerDeleteUser, useGetApiAdminUserManagerListUsers } from "@/API"
import { ActionIcon, Box, Text, Button, Group, Modal, Pagination, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useState } from "react";
import { CreateUserDialog } from "./CreateUserDialog";
import { useQueryClient } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { DataTable } from 'mantine-datatable';
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { useNavigate } from "@tanstack/react-router";

export function UserManager() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [createUser_opened, { open: createUser_open, close: createUser_close }] = useDisclosure(false);
    const DeleteUser_Mutation = useDeleteApiAdminUserManagerDeleteUser()
    const [activePage, setPage] = useState(1);
    const pageCount = 10
    const [search, setSearch] = useDebouncedState("", 500)
    var list = useGetApiAdminUserManagerListUsers({ search: search, skip: (activePage - 1) * pageCount, take: pageCount })
    return <>
        <Button onClick={() => createUser_open()}>
            新增使用者
        </Button>
        <TextInput
            label="使用者名稱搜尋"
            defaultValue={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
        />
        {list.isSuccess && <>
            <Pagination value={activePage} onChange={setPage} total={R.ceil(((list.data?.data.count ?? 0) / pageCount), 0)} />
            <DataTable
                withTableBorder
                columns={[
                    { accessor: 'id' },
                    { accessor: 'userName', title: "使用者名稱" },
                    {
                        accessor: 'actions',
                        title: <Box mr={6}>操作</Box>,
                        textAlign: 'right',
                        render: (data) => (
                            <Group gap={4} justify="right" wrap="nowrap">
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    onClick={() => {
                                        if (data.id)
                                            navigate({ to: "/admin/UserManager/$userId", params: { userId: data.id } })
                                    }}
                                >
                                    <IconEdit size={16} />
                                </ActionIcon>
                                <ActionIcon
                                    size="sm"
                                    variant="subtle"
                                    color="red"
                                    onClick={() => {
                                        modals.openContextModal({
                                            modal: "ConfirmCountDown",
                                            title: '刪除使用者',
                                            innerProps: {
                                                countDownSecond: 60,
                                                children: (
                                                    <Text size="sm">
                                                        是否要刪除使用者"{data.userName}"?
                                                    </Text>
                                                ),
                                                labels: {
                                                    confirm: '刪除', cancel: '取消'
                                                },
                                                confirmProps: { color: 'red' },
                                                onConfirm: async () => {
                                                    try {
                                                        if (data.id) {
                                                            await DeleteUser_Mutation.mutateAsync({ params: { userId: data.id } })
                                                            queryClient.invalidateQueries({ queryKey: list.queryKey })
                                                            notifications.show({ message: "刪除成功" })
                                                        }
                                                    } catch (e) {
                                                        notifications.show({ message: "刪除失敗", color: "red" })
                                                    }
                                                },
                                            },

                                        });
                                    }}
                                >
                                    <IconTrash size={16} />
                                </ActionIcon>
                            </Group>
                        ),
                    },
                ]}
                records={list.data.data.users ?? []}
            />
        </>}
        <CreateUserDialog opened={createUser_opened} onClose={() => createUser_close()} />
    </>
}