import { AuthState, AuthStateRole } from '@/API'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface AudioRecordStateStore {
    needSave: boolean,
    SetNeedSave: (value: boolean) => void
}
export const useAudioRecordStateStore = create<AudioRecordStateStore>()(
    devtools(
        immer((set) => ({
            needSave: false,
            SetNeedSave: (value: boolean) =>
                set((state) => {
                    // console.log("=======")
                    // console.log(value)
                    state.needSave = value
                }),
        }))),
)