import { getGetApiAudioRecordJobGetTranscriptionJobQueryKey, useGetApiAudioRecordJobGetTranscriptionJob, usePostApiAudioRecordJobCreateTranscriptionJob } from "@/API"
import { Button, Flex } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

export function CreateAudioTranscriptionJobButton(props: { workspaceId: string, audioRecordId: string }) {
    const queryClient = useQueryClient()
    const create_Mutation = usePostApiAudioRecordJobCreateTranscriptionJob()
    const [loading, setLoading] = useState(false)
    return <Button loading={loading} variant="gradient" size="xl" onClick={async () => {
        setLoading(true)
        try {
            await create_Mutation.mutateAsync({
                params: {
                    workspaceId: props.workspaceId,
                    audioRecordId: props.audioRecordId,
                    language: "zh"
                }
            })
            await queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordJobGetTranscriptionJobQueryKey({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }) })
            notifications.show({ message: "開始轉錄" })
        } catch (e) {
            notifications.show({ message: "轉錄失敗", color: "red" })
        }
        setLoading(false)
    }}>音檔轉錄</Button>
}