import { AudioRecordEntry, PostApiWorkspaceCreateParams } from '@/API';
import { Button, Checkbox, Group, NumberInput, Stack, Textarea, TextInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as R from "remeda";
export function EditRecordEntryForm(props: { initValue: AudioRecordEntry, onSubmit: (data: AudioRecordEntry) => void }) {
    const form = useForm<{
        minute: number,
        second: number,
        endMinute: number,
        endSecond: number,
        text: string,
    }>({
        initialValues: {
            minute: Math.floor((props.initValue.time ?? 0) / 60),
            second: Math.floor((props.initValue.time ?? 0) % 60),
            endMinute: Math.floor(((props.initValue.time ?? 0) + (props.initValue.duration ?? 0)) / 60),
            endSecond: Math.floor(((props.initValue.time ?? 0) + (props.initValue.duration ?? 0)) % 60),
            text: props.initValue.text ?? "",
        },
    });

    return (
        <form onSubmit={form.onSubmit((values) => {
            var data = R.clone(props.initValue)
            data.time = values.minute * 60 + values.second
            data.duration = values.endMinute * 60 + values.endSecond - data.time
            data.text = values.text
            props.onSubmit(data);
        })}>
            <div style={{ overflowY: 'hidden' }}>
                <Stack gap={0}>
                    <Text size="md" fw={500}>開始時間</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <NumberInput
                            {...form.getInputProps('minute')}
                            decimalScale={0}
                            label="分"
                            min={0}
                            max={99}
                            style={{ marginRight: 10 }}
                        />
                        <NumberInput
                            {...form.getInputProps('second')}
                            decimalScale={0}
                            label="秒"
                            min={0}
                            max={99}
                        />
                    </div>
                    <br />
                    <Text size="md" fw={500}>結束時間</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <NumberInput
                            {...form.getInputProps('endMinute')}
                            decimalScale={0}
                            label="分"
                            min={0}
                            max={99}
                            style={{ marginRight: 10 }}
                        />
                        <NumberInput
                            {...form.getInputProps('endSecond')}
                            decimalScale={0}
                            label="秒"
                            min={0}
                            max={99}
                        />
                    </div>
                    <br />
                    <Textarea
                        {...form.getInputProps('text')}
                        label="文字"
                        rows={5}
                    />
                </Stack>
                <div style={{ marginTop: 20, textAlign: 'right' }}>
                    <Button type="submit" color="blue">
                        確認
                    </Button>
                </div>
            </div>
        </form>
    );
}