import { useAuthStore } from "@/Stores/AuthStore";
import { Text, Button } from '@mantine/core';
import { ChangeDisplayNameDialog } from "./ChangeDisplayNameDialog";
import { useDisclosure } from "@mantine/hooks";


export function SettingsPage() {
    var auth = useAuthStore();
    const [ChangeDisplayName_opened, { open: ChangeDisplayName_open, close: ChangeDisplayName_close }] = useDisclosure(false);
    return <>
        <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10}>
            帳號:{auth.data.userName}
        </Text>
        <div style={{ display: "flex", alignContent: "center" }}>
            <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10}>
                名稱:{auth.data.displayName}
            </Text>
            <Button ml={10} onClick={ChangeDisplayName_open}>修改名稱</Button>
        </div>
        <ChangeDisplayNameDialog opened={ChangeDisplayName_opened} onClose={ChangeDisplayName_close} />
    </>;
}
