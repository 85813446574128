import { AppShell, Text, Box, Burger, Button, Group, Stack, Divider, Center } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet, useNavigate, useMatchRoute, UseMatchRouteOptions, redirect, useRouterState, useSearch } from '@tanstack/react-router';
import { PropsWithChildren, useEffect } from 'react';
import { IconCategory, IconChartBar, IconChartLine, IconClockHour3, IconHome, IconKey, IconList, IconMoneybag, IconServer, IconSettings, IconStar } from '@tabler/icons-react';
import "App.css"
import { useAuthStore } from './Stores/AuthStore';
import { getApiAuthState } from './API';
import { LoginButton, LogoutButton } from './Auth/LoginPage';
import { NavigationButton } from './Components/NavigationButton';
import { AxiosError } from 'axios';

function AdminNavigation() {
  return <>
    <NavigationButton match={{ to: "/admin/UserManager", fuzzy: true }} ><IconList style={{ marginRight: "10px" }} />使用者管理</NavigationButton>
    <NavigationButton match={{ to: "/admin/TTSUsage", fuzzy: true }} ><IconChartBar style={{ marginRight: "10px" }} />使用狀況查詢</NavigationButton>
    <NavigationButton match={{ to: "/admin/LinePay", fuzzy: true }} ><IconMoneybag style={{ marginRight: "10px" }} />LinePay查詢</NavigationButton>
    <NavigationButton match={{ to: "/admin/AudioToTextBalancer", fuzzy: true }} ><IconServer style={{ marginRight: "10px" }} />外部服務狀態</NavigationButton>
    <NavigationButton match={{ to: "/admin/ServiceMetric", fuzzy: true }} ><IconChartLine style={{ marginRight: "10px" }} />服務指數</NavigationButton>
  </>
}
function UserNavigation() {
  return <>
    <NavigationButton match={{ to: "/workspace", fuzzy: true }} ><IconCategory style={{ marginRight: "10px" }} />工作空間</NavigationButton>
    <NavigationButton match={{ to: "/AppKey", fuzzy: true }} ><IconKey style={{ marginRight: "10px" }} />授權金鑰管理</NavigationButton>
    <NavigationButton match={{ to: "/PeriodCreditKey", fuzzy: true }} ><IconStar style={{ marginRight: "10px" }} />訂閱管理</NavigationButton>
    <NavigationButton match={{ to: "/CreditKey", fuzzy: true }} ><IconClockHour3 style={{ marginRight: "10px" }} />儲值時數管理</NavigationButton>
    <NavigationButton match={{ to: "/TTSUsage", fuzzy: true }} ><IconChartBar style={{ marginRight: "10px" }} />使用查詢</NavigationButton>
    <NavigationButton match={{ to: "/Payment", fuzzy: true }} ><IconMoneybag style={{ marginRight: "10px" }} />訂單查詢</NavigationButton>
    <NavigationButton match={{ to: "/Settings", fuzzy: true }} ><IconSettings style={{ marginRight: "10px" }} />個人設定</NavigationButton>
  </>
}

function Navigation() {
  const auth = useAuthStore()
  const search = useSearch({ strict: false })
  return <>
    {/* <Button variant="subtle" onClick={() => { navigate({ to: "/" }); }}>首頁</Button> */}
    <Stack w="100%" gap={5}>
      <NavigationButton match={{ to: "/" }} ><IconHome style={{ marginRight: "10px" }} />首頁</NavigationButton>
      {auth.data.role == "admin" && <AdminNavigation />}
      {auth.data.role == "user" && <UserNavigation />}
      <Box pos="absolute" bottom={20} w="95%">
        <Divider my="lg" />
        <Center>
          <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10} gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
            {auth.data.displayName}
          </Text>
        </Center>
        {!auth.data.userName ? <LoginButton variant="subtle" color="" redirect={search?.redirect as any} /> : <LogoutButton variant="light" color="gray" />}
      </Box>
    </Stack>
  </>
}
export default function App() {
  const auth = useAuthStore()
  const router = useRouterState();
  const matchRoute = useMatchRoute()
  const navigate = useNavigate()
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  useEffect(() => {
    auth.SetUpdating(true)
    try {
      getApiAuthState().then(result => {
        if (result.data.userName && result.data.role)
          auth.Update(result.data)
      }).catch((e: AxiosError) => {
        var index = matchRoute({ to: "/" })
        var adminLogin = matchRoute({ to: "/admin/login" })
        var nav = router.location.pathname.startsWith("/nav")
        if (e.status == 401 && !index && !adminLogin && !nav) {
          navigate({ to: "/", search: { redirect: router.location.pathname } })
        }
      })
    } catch (e) {

    }
    auth.SetUpdating(false)
  }, [])
  return (
    <>
      {router.location.pathname.startsWith("/nav") ? <Outlet /> :
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: 'sm',
            collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
          }}
          padding="md"
        >
          <AppShell.Header>
            <Group h="100%" px="md">
              <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
              <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
            </Group>
          </AppShell.Header>
          <AppShell.Navbar py="md" px={4}>
            <Navigation />
          </AppShell.Navbar>

          <AppShell.Main h="100vh">
            <Outlet />
          </AppShell.Main>
        </AppShell>}
    </>
  );
}