import { getGetApiAudioRecordJobGetTranscriptionJobQueryKey, useDeleteApiAudioRecordJobDeleteTranscriptionJob, useGetApiAudioRecordJobGetTranscriptionJob, usePostApiAudioRecordJobCreateTranscriptionJob } from "@/API"
import { SecondToString } from "@/Helper/string"
import { Alert, Button, Flex, Modal, Progress, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { notifications } from "@mantine/notifications"
import { IconInfoCircle } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { PropsWithChildren, useEffect, useState } from "react"
import * as R from "remeda";

export function AudioTranscriptionJobInfo(props: { workspaceId: string, audioRecordId: string, onDelete?: () => void }) {
    const queryClient = useQueryClient()
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false)
    const info = useGetApiAudioRecordJobGetTranscriptionJob({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
    const delete_Mutation = useDeleteApiAudioRecordJobDeleteTranscriptionJob()
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        if (info.data?.data.transciptionTime && info.data?.data.duration)
            setProgress(R.round(0)(100 * R.clamp(info.data?.data.transciptionTime / info.data?.data.duration, { min: 0, max: 100 })))
    }, [info.data?.data.transciptionTime])
    return <>
        <Button color="red" onClick={() => {
            modals.openConfirmModal({
                title: '取消轉錄',
                children: (
                    <Text size="sm">
                        是否要取消轉錄?
                    </Text>
                ),
                labels: { confirm: '確定', cancel: '取消' },
                confirmProps: { color: "red" },
                onConfirm: async () => {
                    try {
                        await delete_Mutation.mutateAsync({
                            params: { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }
                        })
                        queryClient.invalidateQueries({ queryKey: info.queryKey })
                        notifications.show({ message: "取消成功" })
                        props.onDelete?.()
                    } catch (e) {
                        notifications.show({ message: "取消失敗", color: "red" })
                    }
                },
            });
        }}>
            取消轉錄
        </Button>
        {info.data?.data.createdTime &&
            <Text>
                開始時間:{dayjs(info.data?.data.createdTime).toDate().toLocaleString()}
            </Text>}
        {info.data?.data.updatedTime &&
            <Text>
                更新時間:{dayjs(info.data?.data.updatedTime).toDate().toLocaleString()}
            </Text>}
        <Text>
            {SecondToString(info.data?.data.transciptionTime ?? 0)} / {SecondToString(info.data?.data.duration ?? 0)}
        </Text>
        <Progress.Root size="xl">
            <Progress.Section value={progress}>
                <Progress.Label>{progress}%</Progress.Label>
            </Progress.Section>
        </Progress.Root>
        {info.data?.data.message &&
            <Alert mt={10} variant="light" color="red" title="訊息" icon={<IconInfoCircle />}>
                {info.data?.data.message}
            </Alert>}
    </>
}