import { AudioRecordEntry } from "@/API";
import { SecondToString } from "@/Helper/string";
import { Text, Button, useMantineTheme, ActionIcon, Box, Group, Divider } from "@mantine/core";
import { IconEdit, IconPlayerPlayFilled, IconPlayerRecordFilled, IconUserFilled } from "@tabler/icons-react";
import dayjs from "dayjs";

export function RecordEntryCard({ isEditable, recordEntry, isSelected, onPlay, onEdit, onEditSpeakers }: { isEditable: boolean, recordEntry: AudioRecordEntry, isSelected: boolean, onPlay?: () => void, onEdit?: () => void, onEditSpeakers?: () => void }) {
    const theme = useMantineTheme();
    const activeColor = theme.colors[theme.primaryColor][5]
    const inactiveColor = "gray"
    return <>
        <Group align="center" justify="start" gap={0} >
            <ActionIcon
                variant="subtle"
                radius="lg"
                style={{ left: '-6px', padding: '0px' }}
                onClick={onPlay}
            >
                <IconPlayerPlayFilled
                    color={isSelected ? activeColor : inactiveColor}
                    size={20}
                />
            </ActionIcon >
            {isEditable &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ left: '-6px', padding: '0px' }}
                    onClick={onEdit}
                >
                    <IconEdit
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon >
            }
            <Text
                onClick={onPlay}
                c={isSelected ? activeColor : inactiveColor}
            >
                {SecondToString(Math.max(recordEntry.time ?? 0, 0))}~{SecondToString((recordEntry.time ?? 0) + (recordEntry.duration ?? 0))}

            </Text>
            <Text
                style={{ marginLeft: '5px' }}
                c={isSelected ? activeColor : inactiveColor}
            >
                {recordEntry.speakers?.join(', ')}
            </Text>
            {isEditable &&
                <ActionIcon
                    variant="subtle"
                    radius="lg"
                    style={{ left: '5px', padding: '0px' }}
                    onClick={onEditSpeakers}
                >
                    <IconUserFilled
                        color={isSelected ? activeColor : inactiveColor}
                        size={20}
                    />
                </ActionIcon>
            }
        </Group>

        <Group gap={0} ml="2.5px">
            <Divider orientation="vertical" size="3px" pos="relative" left={3} color={isSelected ? activeColor : inactiveColor} />
            <Group gap={0} flex={1}>
                <Text
                    style={{
                        marginLeft: '15px',
                        marginBottom: '0px',
                        color: isSelected ? activeColor : inactiveColor,
                        whiteSpace: "pre-wrap"
                    }}
                    size="sm"
                >
                    {recordEntry.text}
                </Text>
            </Group>
        </Group>
    </>
}