import React, { CSSProperties, useCallback, useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";

function Row<TData>({ data, index, setSize, windowWidth, components }: { data: TData[], index: number, setSize: (index: number, height: number) => void, windowWidth: number, components: (data: TData[], index: number) => React.ReactNode }) {
    const rowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setSize(index, rowRef.current?.getBoundingClientRect().height ?? 0);
    }, [setSize, index, windowWidth]);

    return (
        <div ref={rowRef}>
            {components(data, index)}
        </div>
    );
};
export function VirtualList<TData>({ data, components, style, heightOffset = 0, widthOffset = 0 }: { data: TData[], components: (data: TData[], index: number) => React.ReactNode, style?: CSSProperties, heightOffset?: number, widthOffset?: number }) {
    const autoResizeRef = useRef<AutoSizer>(null);
    const listRef = useRef<List>(null);
    const sizeMap = useRef<{ [key: number]: number }>({});
    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current?.resetAfterIndex(index);
    }, []);
    const getSize = (index: number) => sizeMap.current[index] || 50;
    return <AutoSizer style={style} ref={autoResizeRef} >
                {({ height, width }) => {
                    return <List
                        ref={listRef}
                        height={height + heightOffset}
                        width={width + widthOffset}
                        itemCount={data.length}
                        itemSize={getSize}
                        itemData={data}
                    // innerElementType={ListContainer}
                    >
                        {({ data, style, index }) => <div style={style}>
                            <Row
                                data={data}
                                index={index}
                                setSize={setSize}
                                windowWidth={width}
                                components={components}
                            />
                        </div>
                        }
                    </List>
                }}
            </AutoSizer>
}