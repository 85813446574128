import { postApiCreditKeyLinePayPurchase } from "@/API";
import { Card, Stack, Text, Button, Center } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useState } from "react";

export function CreditKeyPaymentCard(props: { productId: string, title: string, comment: string, priceLabel: string, subtitle: string }) {
    const [purchaseLoading, setPurchaseLoading] = useState(false)
    return <>
        <Card w={200} shadow="sm" padding="lg" radius="md" withBorder>
            <Stack align="center" gap={10}>
                <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
                    {props.title}
                </Text>
                <Stack gap={0}>
                    <Text fw={700} style={{ fontSize: "20px" }} c="#6e6e6e" variant="text">
                        {props.subtitle}
                    </Text>
                    <Center>
                        <Text fw={400} h={12} style={{ fontSize: "12px" }} c="#6e6e6e" variant="text">
                            {props.comment}
                        </Text>
                    </Center>
                </Stack>
                <Button
                    fullWidth
                    loading={purchaseLoading}
                    variant="gradient"
                    gradient={{ from: 'blue', to: 'cyan', deg: 90 }}
                    onClick={async () => {
                        try {
                            setPurchaseLoading(true)
                            var result = await postApiCreditKeyLinePayPurchase({ productId: props.productId })
                            if (result.data.web)
                                window.open(result.data.web, '_blank')?.focus();
                        }
                        catch (error) {
                            notifications.show({ message: "LinePay發生錯誤，請通知管理員", color: "red" })
                        }
                        setPurchaseLoading(false)
                    }}
                >
                    {props.priceLabel}
                </Button>
            </Stack>
        </Card>
    </>
}