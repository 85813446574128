import { Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
export function EditAudioRecordNameForm(props: { initValue: string, onSubmit: (data: string) => void }) {
    const form = useForm<{
        name: string
    }>({
        initialValues: {
            name: props.initValue,
        },
    });

    return (
        <form onSubmit={form.onSubmit((values) => {
            props.onSubmit(values.name);
        })}>
            <div >
                <TextInput
                    {...form.getInputProps('name')}
                    label="名稱"
                />
                <div style={{ marginTop: 20, textAlign: 'right' }}>
                    <Button type="submit" color="blue">
                        確認
                    </Button>
                </div>
            </div>
        </form>
    );
}