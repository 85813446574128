import { getApiWorkspaceList, getGetApiWorkspaceListQueryKey, useDeleteApiWorkspaceDelete, usePutApiWorkspaceRename, Workspace, WorkspaceUserRole } from "@/API";
import { Text, Card, Group, Button, Menu, ActionIcon, rem, LoadingOverlay, Modal } from "@mantine/core";
import { WorkspaceUserRoleBadge } from "../WorkspaceUserRoleText";
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useAuthStore } from "@/Stores/AuthStore";
import { EditWorkspaceNameForm } from "./EditWorkspaceNameForm";
import { useDisclosure } from "@mantine/hooks";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";

export function WorkspaceListCard({ workspace, onEnter }: { workspace: Workspace, onEnter: () => void }) {
    const queryClient = useQueryClient()
    const auth = useAuthStore();
    const workspaceOwnerAuth = UseWorkspaceAuth(workspace.id!, "Owner")
    const deleteWorkspace_Mutation = useDeleteApiWorkspaceDelete()
    const [deleteWorkspaceLoading, setDeleteWorkspaceLoading] = useState(false)
    var user = useMemo(() => workspace.users?.find(x => x.userName == auth.data.userName), [workspace.users])
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const rename_Mutation = usePutApiWorkspaceRename()
    return <>
        <Card w={400} shadow="sm" padding="lg" pt={5} radius="md" withBorder>
            <LoadingOverlay
                visible={deleteWorkspaceLoading}
                zIndex={1000}
                loaderProps={{ type: 'bars' }}
            />
            <Group justify="space-between">
                <Group mt="md" mb="xs">
                    <Text fw={500}>{workspace.name}</Text>
                    <WorkspaceUserRoleBadge role={user?.role ?? "None"} />
                </Group>
                {workspaceOwnerAuth &&
                    <Menu withinPortal position="bottom-end" shadow="sm">
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                                <IconDots style={{ width: rem(16), height: rem(16) }} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
                                onClick={() => {
                                    editName_open()
                                }}
                            >
                                修改名稱
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                color="red"
                                onClick={() => {
                                    if (workspace.id) {
                                        modals.openContextModal({
                                            modal: "ConfirmCountDown",
                                            title: "刪除工作區域",
                                            innerProps: {
                                                countDownSecond: 3,
                                                children: (
                                                    <Text size="sm">
                                                        是否要刪除工作區域{workspace.name}?
                                                    </Text>
                                                ),
                                                labels: { confirm: '刪除', cancel: '取消' },
                                                confirmProps: { color: 'red', loading: deleteWorkspaceLoading },
                                                onConfirm: async () => {
                                                    setDeleteWorkspaceLoading(true)
                                                    try {
                                                        if (workspace.id)
                                                            await deleteWorkspace_Mutation.mutateAsync({
                                                                params: {
                                                                    id: workspace.id
                                                                }
                                                            })
                                                        notifications.show({ message: "刪除成功" })
                                                        queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                                                    }
                                                    catch (e) {
                                                        notifications.show({ message: "刪除失敗", color: "red" })
                                                    }
                                                    setDeleteWorkspaceLoading(false)
                                                }
                                            }
                                        })
                                    }
                                }}
                            >
                                刪除
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                }
            </Group>



            {/* <Text size="sm" c="dimmed">
                
                With Fjord Tours you can explore more of the magical fjord landscapes with tours and
                activities on and around the fjords of Norway
            </Text> */}

            <Button color="blue" fullWidth mt="md" radius="md" onClick={() => {
                onEnter()
            }}>
                進入
            </Button>
            <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
                <EditWorkspaceNameForm initValue={workspace.name ?? ""} onSubmit={async v => {
                    if (workspace.id) {
                        try {
                            await rename_Mutation.mutateAsync({ params: { id: workspace.id, name: v } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                            editName_close()
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }

                    }
                }} />
            </Modal>
        </Card>
    </>
}
