import * as R from "remeda";
import { PostApiAdminAudioToTextBalancerSearchAvergeParams, useGetApiAdminAudioToTextBalancerListSource, usePostApiAdminAudioToTextBalancerSearch, usePostApiAdminAudioToTextBalancerSearchAverge } from "@/API";
import { Button, Card, Group, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { DateTimePicker } from "@mantine/dates";
import { useState } from "react";
import { modals } from "@mantine/modals";
import { AxiosError } from "axios";
import { notifications } from "@mantine/notifications";
import { AudioToTextRecordAvergeViewer } from "./AudioToTextRecordAvergeViewer";
import { AudioToTextRecordListViewer } from "./AudioToTextRecordListViewer";

export function SearchAudioToTextRecord() {
    var [loading, setLoading] = useState(false)
    var sources = useGetApiAdminAudioToTextBalancerListSource()
    var searchAverage_Mutation = usePostApiAdminAudioToTextBalancerSearchAverge()
    var search_Mutation = usePostApiAdminAudioToTextBalancerSearch()
    const form = useForm<PostApiAdminAudioToTextBalancerSearchAvergeParams>({
        initialValues: {
            startTime: dayjs().add(-1, "hour").toISOString(),
            endTime: dayjs().toISOString(),
        },
    });
    return <Card shadow="sm" padding="xs" radius="md" withBorder>
        <form>
            {sources.data?.data && sources.data?.data.length > 0 &&
                <Select
                    label="名稱"
                    data={[...R.unique(sources.data?.data.map(x => x.name!)) ?? ""]}
                    value={form.getValues().source}
                    onChange={x => form.setFieldValue("source", x!)}
                />
            }
            <DateTimePicker
                label="開始時間"
                locale="zh-tw"
                value={dayjs(form.getValues().startTime).toDate()}
                onChange={x => form.setFieldValue("startTime", x?.toISOString())}
            />
            <DateTimePicker
                rightSectionWidth={100}
                rightSectionProps={{ style: { justifyContent: "flex-end" } }}
                rightSection={<Button onClick={() => form.setFieldValue("endTime", new Date().toISOString())}>當前時間</Button>}
                label="結束時間"
                locale="zh-tw"
                value={dayjs(form.getValues().endTime).toDate()}
                onChange={x => form.setFieldValue("endTime", x?.toISOString())}
            />
            <Group justify="flex-start" mt="md">
                <Button loading={loading} type="submit" onClick={async () => {
                    setLoading(true)
                    try {
                        var result = await searchAverage_Mutation.mutateAsync({ params: form.values })
                        modals.open({
                            title: form.getValues().source,
                            size: "lg",
                            children: (
                                <AudioToTextRecordAvergeViewer data={result.data} />
                            ),
                        });
                    }
                    catch (error) {
                        const err = error as AxiosError
                        if (err && err.response?.status == 404)
                            notifications.show({ message: "查無資料" })
                        else
                            notifications.show({ message: "資料錯誤" })
                    }
                    setLoading(false)
                }}>搜尋平均</Button>
                <Button loading={loading} type="submit" onClick={async () => {
                    setLoading(true)
                    try {
                        var result = await search_Mutation.mutateAsync({
                            params: {
                                ...form.values, limit: 20
                            }
                        })
                        modals.open({
                            title: form.getValues().source,
                            size: "lg",
                            children: (
                                <AudioToTextRecordListViewer data={result.data} />
                            ),
                        });
                    }
                    catch (error) {
                        const err = error as AxiosError
                        if (err && err.response?.status == 404)
                            notifications.show({ message: "查無資料" })
                        else
                            notifications.show({ message: "資料錯誤" })
                    }
                    setLoading(false)
                }}>搜尋最近20筆</Button>
            </Group>
        </form>
    </Card>
}